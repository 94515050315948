import React from "react"
import { graphql } from "gatsby"
import moment from 'moment'
import 'moment/locale/fr'
import Layout from "../components/layout"
import Divider from '../components/divider'
import Trainer from '../components/trainer'
import { Card, Container, Row, Col, Table } from 'react-bootstrap'
import SEO from '../components/seo'
import ReactMarkdown from 'react-markdown'
import WorkshopForm from '../components/WorkshopForm'
import { getWorshopsConfiguration } from '../utils/workshops'
import axios from "axios"

class WorkshopPage extends React.Component {
    constructor (props) {
        super(props)
        const { workshopMdx : { workshop }, trainerMdx: { trainer } } = props.data

        this.state = {
            trainer: trainer,
            workshop: workshop,
            workshopConfiguration: {},
        }
        
    }

    componentDidMount() {
        getWorshopsConfiguration().then(workshopsConfiguration => {
            if (this.state.workshop.path in workshopsConfiguration) {
                this.setState({
                    workshopConfiguration: workshopsConfiguration[this.state.workshop.path]
                })
            }
        }).then(() => {
            axios({method: 'post', url:'/.netlify/functions/workshopBookings', data: {path: this.state.workshop.path}}).then((response) => {
                const workshopBookings = response.data.workshopCount || {}
                this.setState({workshopConfiguration: {
                    ...this.state.workshopConfiguration,
                    dates: this.state.workshopConfiguration.dates.map(date => {
                        const booked = workshopBookings[date.start + '_' + date.end] || 0
                        const full = booked >= this.state.workshopConfiguration.nbPlaces
                        return {...date, count: booked, full: full}
                    })?.filter(
                        date => moment(date.start) > moment() ? date : null 
                    ).sort((first, second) => {
                        return moment(first.start) - moment(second.start)
                    })
                }})
            }).catch(err => {
                
            })
        })
    }

    render() {
        const newDates = this.state.workshopConfiguration.dates
        const workshop = this.state.workshop
        
        return (
            <Layout>
                  <SEO title={workshop.title} />
                  <Container className="my-md-4 py-4 rounded text-justify bg-green-light">
                      <Row>
                          <Col md={8} xs={12} className="mx-auto">
                              
                              <h2 className="text-center text-uppercase my-4">{workshop.title}</h2>
                              <ReactMarkdown parserOptions={{ commonmark: true }} source={workshop.description} />
                              <Divider />
      
                              {
                                  ! newDates ? 
                                  (
                                    <h3 className="text-center">Aucune session pour cet atelier pour le moment</h3>
                                  ) : (
                                      <div>
                                          <Card>
                                            <Card.Header as="h3" className="text-center">Informations sur l'atelier</Card.Header>
                                            <Card.Body>
                                            <h5><strong>Espace où se déroule l'atelier : </strong>{workshop.room}</h5>
                                            <h5><strong>Age des participants : </strong>{workshop.age}</h5>
                                            <h5><strong>Nombre de places :</strong> {this.state.workshopConfiguration.nbPlaces}</h5>
                                            <h5><strong>Tarif :</strong> {this.state.workshopConfiguration.price}€</h5>
                                            <h5><strong>Prochaines dates : </strong></h5>
                                            <Table>
                                                <thead>
                                                    <tr>
                                                        <th>Date</th>
                                                        <th>Places disponibles</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    newDates.map((date, index) => {
                                                        if (index > 4) {
                                                            return null
                                                        }
                                                        let startDate = moment(date.start)
                                                        let endDate = moment(date.end)
                                                        const dateText = `${startDate.format('dddd DD MMMM')} de ${startDate.format("HH[h]mm")} à ${endDate.format("HH[h]mm")}`
                                                        const nbPlaces = parseInt(this.state.workshopConfiguration.nbPlaces)
                                                        const placesText = date.count >= nbPlaces ? 'COMPLET' : String(nbPlaces - date.count)

                                                        return <tr key={"date_" + index}>
                                                            <td>{dateText}</td>
                                                            <td>{placesText}</td>
                                                        </tr>
                                                    })
                                                }

                                                </tbody>
                                            </Table>
                                            </Card.Body>
                                        </Card>
                                        <Row>
                                            <Col md={8} xs={12} className="mx-auto py-3 mb-3">
                                                <WorkshopForm workshopConfiguration={this.state.workshopConfiguration}></WorkshopForm>
                                            </Col>
                                        </Row>
                                    </div>
                                  )
                              }
                          </Col>
                          <Col md={4} xs={12} className="mx-auto">
                              <div>
                                  <Trainer trainer={this.state.trainer}/>
                              </div>
                          </Col>
                      </Row>
      
                  </Container>
            </Layout>
          )
        
    }
}

export default WorkshopPage

export const pageQuery = graphql`
    query($title: String, $trainer_name: String) {
        workshopMdx: mdx(frontmatter: {title: { eq: $title }}) {
            workshop:frontmatter {
                title
                path
                description
                room
                age
            }
        }
        trainerMdx:mdx(frontmatter: {full_name: {eq: $trainer_name}}, fileAbsolutePath: {regex: "/content/trainer/.*/"}) {
            trainer:frontmatter {
                full_name
                description
                photo {
                    childImageSharp {
                        fluid(maxWidth:500) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        }
    }`
