import React from 'react'
import { Card } from 'react-bootstrap'
import ReactMarkdown from 'react-markdown'

export default ({trainer}) => {

    const imageSrc = trainer.photo ? trainer.photo.childImageSharp.fluid.src : ''

    return (
        <Card className="mb-5 mx-auto" style={{
            maxWidth: "500px"
        }}> 
            <Card.Img 
            variant="top" 
            src={imageSrc} 
            alt={trainer.full_name} className={imageSrc ? '' : 'd-none'}/>
            <Card.Body>
                <Card.Title>{trainer.full_name}</Card.Title>
                <Card.Text as="div">
                    <ReactMarkdown parserOptions={{ commonmark: true }} source={trainer.description} />
                </Card.Text>
            </Card.Body>
        </Card>
        
    )
}
