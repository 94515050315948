import React from "react"
import { Formik } from "formik";
import { Alert, Button, InputGroup, Form, Modal } from 'react-bootstrap'
import { FaEnvelope } from 'react-icons/fa'
import * as Yup from 'yup'
import { navigate } from 'gatsby'
import axios from "axios"
import moment from 'moment'
import 'moment/locale/fr'
import { loadStripe } from '@stripe/stripe-js'


class WorkshopForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            smShow: false,
            bookingOption: null,
            stripe: null,
            errorMessage: ''
        }
        this.loadStripeLib = this.loadStripeLib.bind(this)
    }

    async loadStripeLib() {
        try {
          const stripe = await loadStripe(process.env.GATSBY_STRIPE_PUBLIC_KEY);
          this.setState({ stripe });
        } catch {
          // do nothing
        }
      }

    componentDidMount() {
        this.loadStripeLib()
    }

    renderChoice() {
        return <>
            <Button className="btn btn-green d-block mx-auto my-4" onClick={() => {this.setState({bookingOption: 'accessCard'})}}>
                Je souhaite réserver avec ma carte d'accès
            </Button>

            <Button className="btn btn-green d-block mx-auto my-4" 
                onClick={() => {this.setState({bookingOption: 'card'}) }}>
                    Je souhaite réserver en payant par carte bancaire
            </Button>
        </>
    }

    renderForm() {
        const newDates = this.props.workshopConfiguration.dates?.filter(
            date => ! date.full
        ) || []
        let WorkshopSchema = {
            email: Yup.string().email("Email invalide"),
            date: Yup.number().required(),
            name: Yup.string().required(),
            age: Yup.string().required(),
            phone:Yup.string(),
        }

        return <Formik
            initialValues={
                { 
                    email: '', 
                    date: 0, 
                    name: '',
                    age: '',
                    phone: '',
                    card: ''
                }
            }
            validationSchema={Yup.object().shape(WorkshopSchema)}
            onSubmit={(values, {setStatus, setSubmitting }) => {
                this.setState({errorMessage: ''})
                const payload = {
                    ...values,
                    workshop: {
                        date: newDates[values.date],
                        workshop_path: this.props.workshopConfiguration.path,
                        workshop_title: this.props.workshopConfiguration.title,
                    }
                }
                axios.post('/.netlify/functions/workshopBooking', payload
                ).then(response => {
                    if (response.data.session) {
                        this.state.stripe.redirectToCheckout({
                            sessionId: response.data.session.id
                        }).then(response => {
                            console.log("super")
                        }).catch(error => {
                            console.log("error")
                        });
                    } else {
                        this.setState({
                            smShow: true
                        })
                        setSubmitting(false);
                        navigate('/reservation_succes')
                    }
                }).catch(error => {
                    console.log(error)
                    this.setState({errorMessage: error.response.data.msg})
                    setSubmitting(false);
                });
                
            }}
        >
            {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                status,
                setFieldValue
            }) => {
                return (
                <Form noValidate id="workshopForm" onSubmit={handleSubmit}>
                    <Form.Group controlId="Workshop.date" className="mx-auto w-75">
                        <Form.Label className="h5 text-white">Sélectionner la date</Form.Label>
                        <Form.Control as="select" value={values.date} name="date" isInvalid={!!errors.date} onChange={handleChange}>
                        {
                            newDates.map((date, indx) => (
                                <option value={indx} key={indx}>{moment(date.start).format('dddd DD MMMM') + ' de ' + moment(date.start).format("HH[h]mm") + ' à ' + moment(date.end).format("HH[h]mm")}</option>
                            ))
                        }
                        </Form.Control>
                    </Form.Group>
                        <Form.Group controlId="Workshop.Enfant" className="mx-auto w-75">
                        <Form.Label className="h5 text-white">Prénom et nom de l'enfant ou de la personne participant à l'atelier</Form.Label>
                        <Form.Control
                            type="text" placeholder="Nom et prénom" name="name"
                            value={values.name} onChange={handleChange} isInvalid={!!errors.name}
                            />
                        </Form.Group>
                        <Form.Group controlId="Workshop.Age" className="mx-auto w-75">
                            <Form.Label className="h5 text-white">Âge de la personne participant à l'atelier</Form.Label>
                            <Form.Control
                                type="text" placeholder="Âge" name="age"
                                value={values.age} onChange={handleChange} isInvalid={!!errors.age}
                                />
                        </Form.Group>
                        <Form.Group controlId="Workshop.Mail" className="mx-auto w-75">
                            <Form.Label className="h5 text-white">Veuillez renseigner votre email : </Form.Label>
                            <InputGroup>
                                <InputGroup.Prepend>
                                    <InputGroup.Text><FaEnvelope className="prefix grey-text"/></InputGroup.Text>
                                </InputGroup.Prepend>
                                <Form.Control
                                type="email" placeholder="Email" name="email"
                                value={values.email} onChange={handleChange}
                                isInvalid={!!errors.email}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Form.Group controlId="Workshop.Phone" className="mx-auto w-75">
                            <Form.Label className="h5 text-white">Numéro de téléphone</Form.Label>
                            <Form.Control
                                type="tel"
                                placeholder="Numéro de téléphone"
                                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                                name="phone"
                                value={values.phone}
                                onChange={handleChange}
                                isInvalid={!!errors.phone}
                            />
                        </Form.Group>
                        {
                        this.state.bookingOption === 'accessCard' ? (
                            <Form.Group controlId="Workshop.Card" className="mx-auto w-75">
                                <Form.Label className="h5 text-white">Veuillez renseigner votre carte d'accès</Form.Label>
                                <Form.Control 
                                    type="text" placeholder="Carte d'accès" name="card" 
                                    value={values.card} onChange={handleChange}
                                    />
                            </Form.Group>
                            ) : ''
                        }
                    <Button type="submit" 
                    disabled={isSubmitting} 
                    size="lg" className="d-block mx-auto btn-green">Réserver cet atelier</Button>
                    <Modal size="sm"
                        show={this.state.smShow}
                        onHide={() => this.setState({smShow: false})}
                        aria-labelledby="example-modal-sizes-title-sm" centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="example-modal-sizes-title-sm">
                                Inscription à l'atelier
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Votre demande d'inscription à l'atelier a bien été prise en compte</Modal.Body>
                    </Modal>
                </Form>
            )}}
        </Formik>
    }

    render() {
        return (
            <div className="border rounded pt-3">
                <h4 className="text-white text-center py-4">Réservation d'atelier</h4>
                {
                    ! this.state.bookingOption ? this.renderChoice() : this.renderForm()
                }
                <Alert variant="danger" className={'text-center mt-3 ' + (this.state.errorMessage ? '': 'd-none')}>
                    {this.state.errorMessage}
                </Alert>
            </div>
        )
    }

}
 export default WorkshopForm